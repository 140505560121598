import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import { MeshPhongMaterial } from "three";


export default function Cupcake(props) {
    const ref = useRef();
    const {nodes, materials} = useGLTF("/cupcake-marshmallows/scene.gltf");
    
    // console.log(materials, nodes);
  
    // const creamMaterial = materials.Cream;
    // const bakingCupsMaterial = materials.BakingCups;
    // const marshmellowMaterial = materials.Murshmallow_Cream;
    // const cupcakeMaterial = materials.Cupcake;

    const creamMaterial = new MeshPhongMaterial({
        color: 0x4c302b,
        flatShading: true,
        shininess: 30
    });
    const bakingCupsMaterial = new MeshPhongMaterial({
        color: 0x493939,
        flatShading: true,
        shininess: 10
    });
    const marshmellowMaterial = new MeshPhongMaterial({
        color: 0xffffff,
        flatShading: false,
        shininess: 150,
        emissive: 'black'
    });
    const cupcakeMaterial = new MeshPhongMaterial({
        color: 0x2b201a,
        flatShading: false,
        shininess: 10,
        emissive: 'black'
    });

    useFrame(state => {
        const t = state.clock.getElapsedTime();
        ref.current.rotation.x = Math.PI / 10 + Math.cos(t / 8) / 8;
        ref.current.rotation.y = Math.sin(t / 6) / 8;
        ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
        ref.current.position.y = (1 - Math.sin(t / 1.5)) / 10 - 0.75;
    });

    return (
        <group ref={ref} {...props} dispose={null}>
            <mesh 
                castShadow 
                receiveShadow
                geometry={nodes.Cupcake_Cupcake_0.geometry}
                material={cupcakeMaterial} />
            <mesh 
                castShadow 
                receiveShadow
                geometry={nodes.Cream_Cream_0.geometry}
                material={creamMaterial} />
            <mesh 
                castShadow 
                receiveShadow
                geometry={nodes.Murshmallow_Cream_Murshmallow_Cream_0.geometry}
                material={marshmellowMaterial} />
            <mesh 
                castShadow 
                receiveShadow
                geometry={nodes.BakingCups_BakingCups_0.geometry}
                material={bakingCupsMaterial} />
        </group>
    );
}