import './App.css';
import { Canvas } from '@react-three/fiber';
import Cupcake from './Cupcake';
import { ContactShadows, PresentationControls } from '@react-three/drei';
import { Suspense } from 'react';

function App() {

  return (
    <div className="App">

      <div>
        <div className='htmlTopLayer'>
          <img className="logo" src="logo-inverted.png" alt="ITcake logo" />
          <h1>Tomasz Polański</h1>
          <a className="linkedinLink" href='https://www.linkedin.com/in/tomaszpola%C5%84ski'>
            <img src='linkedin-logo-color.png' alt='linkedin logo' title='Linkedin' />
          </a>
        </div>

        <div className='overlayWritings'>
          <span>Frontend</span>
          <span>SAP Fiori</span>
          <span>Angular</span>
          <span>Ionic</span>
          <span>React.js</span>
          <span>SAP UI5</span>
        </div>

        <Suspense fallback={null}>
          <Canvas 
            shadows 
            dpr={[1, 2]}
            camera={{position: [0,0,4], fov: 63 }}>
            <ambientLight intensity={0.4} />
            <spotLight
              position={[10, 10, 10]}
              angle={0.15}
              penumbra={1} />
            <PresentationControls global>
              <Cupcake 
                rotation={[0.2, 0.7, 0]} 
                position={[0, -0.6, 0]} 
                scale={1} />
            </PresentationControls>
            <ContactShadows
              position={[0, -1.6, 0]}
              width={7}
              height={7}
              far={10}
              rotation={[0.2, 0, 0]}
              blur={0.4}
              opacity={0.75}
            />
          </Canvas>
        </Suspense>
        
      </div>
      
    </div>
  );
}

export default App;
